/* eslint-disable */
// prettier-ignore
import MDButton from "components/MDButton";
import cysrConfig from "layouts/cysr/config";

import { DebouncedInput } from "layouts/cysr/comply/components/table-questionnaires";
import { Input } from "layouts/cysr/components/ui/input";
import { Button, Dialog, DialogPanel, Icon } from '@tremor/react';
import { useState } from 'react';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "layouts/cysr/components/ui/form";

export function ComplyInvite({ darkMode, language, questionnaireID, className = "",children = null }) {
  const [isOpen, setIsOpen] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const shortDescription = "Invite someone"
  const description = "Invite people to edit the Questionnaire"

function onSubmit(data) {
  fetch(""
   // `${cysrConfig().api_base_url}account/${accountUUID}/dpo/ask`
    , {
    body: JSON.stringify({
      validator_email: data.email,
      validator_name: data.nominative,
    }),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      "content-type": "application/json",
    },
    method: "POST"
  })
    .then((r) => {
      if (r.ok) {
        // window.location.reload();
        return r.json();
      } else {
        console.error(r);
        return r.json().then(err => {
          throw JSON.stringify(err)
        })
        return r.json()
      }
    })
    .then((response) => {
      console.log(response)
      setOpenDPO(false)
      setDPOData({
        email: data.email,
        validator_name: data.nominative,
        // inviter_name: "Z <abc@dfg.it>",
      });
    }
    ).catch((err) => {
      console.log(err);
      console.log("err3:", err);
      if (JSON.parse(err) && JSON.parse(err).ErrorMessage) {
        toggleSnackbar(JSON.parse(err).ErrorMessage, JSON.parse(err).ErrorDetail, "error", "error", "Error");
      } else if (JSON.parse(err) && JSON.parse(err).error) {
        toggleSnackbar("Error", JSON.parse(err).error, "error", "error", "Error");
      } else {
        toggleSnackbar("Error", "Some error happened", "error", "error", "Error");
      }
      setOpenDPO(false)
      setDPOData(null)
    })
}

  return (
    <div className="flex justify-center">
      <button type='button' className={className} onClick={() => setIsOpen(true)}>
        {children}
      </button>        
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        static={true}
        className={`z-[999999] ${darkMode ? "dark" : "light"}`}
      >
        <DialogPanel className="max-w-lg">
          <div className='flex justify-between'>
            <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>Invitation</p>
            <Button
              className="ml-auto my-0 py-1 flex text-black dark:text-white items-center"
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button>
          </div>
          <h3 className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">{shortDescription}</h3>
          <p className="mt-2 leading-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content whitespace-pre-line">
            {description}
          </p>
          <div>
            {
              /*
            }
                  <div className="mb-6 py-3 px-4 w-full nolg:w-6/12 border border-teal-500/50 bg-teal-500/25 rounded-lg flex flex-col gap-2">
                    <ul className="flex flex-col gap-1 list-disc list-inside marker:text-teal-500">
                      {domains && domains.map((domain) => (
                        <li>{domain[0]}</li>
                      ))}
                    </ul>
                  </div>
                  {
                  */
                  }
                  <div className="text-sm mb-4">
                   maybe text here
                  </div>
                  <div className="mt-4 flex flex-col gap-4 items-center">
                    <div className="w-full nolg:w-6/12 nolg:border-l nolg:border-l-turquoise-300 nolg:dark:border-l-turquoise-700 nolg:pl-4">
                      <Form>
                        <form
                          onSubmit={(data)=>onSubmit(data)}
                          className="w-full flex flex-col gap-4"
                        >
                          <div className="w-full">
                            <div
                              // control={form.control}
                              name="nominative"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="font-regular">
                                    <FormDescription>
                                      desc goes here
                                    </FormDescription>
                                  </FormLabel>

                                  <FormControl 
                                  // </FormItem>onChange={(x) => console.warn(x.target.value)}
                                  >
                                    <Input {...field} />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                          <DebouncedInput
                type="email"
                value={emailValue}
                onChange={value => setEmailValue(value)}
                placeholder={"Insert email"}
                className="w-36 border shadow rounded-tremor-default"
            />
                          <div
                            // control={form.control}
                            name="email"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel className="font-regular">
                                  <FormDescription>
                                    desc of field here
                                  </FormDescription>
                                </FormLabel>
                                <FormControl onChange={(a) => console.log(a.target.value)}>
                                  <Input
                                    // placeholder="example@muscope.com"
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />

                          <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 items-center">
                            <div>
                              <MDButton className="dpo-box-cancel" style={cysrConfig().button_style} size="medium">Cancel</MDButton>
                            </div>
                            <MDButton
                              className="dpo-send-button-icon px-4 py-3 gap-2"
                              size="medium"
                              style={cysrConfig().button_style}
                              title="Title hereee"
                              type="submit"
                            >
                              <svg
                                className="w-4 h-4"
                                width="16"
                                height="16"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.20308 1.04312C1.00481 0.954998 0.772341 1.0048 0.627577 1.16641C0.482813 1.32802 0.458794 1.56455 0.568117 1.75196L3.92115 7.50002L0.568117 13.2481C0.458794 13.4355 0.482813 13.672 0.627577 13.8336C0.772341 13.9952 1.00481 14.045 1.20308 13.9569L14.7031 7.95693C14.8836 7.87668 15 7.69762 15 7.50002C15 7.30243 14.8836 7.12337 14.7031 7.04312L1.20308 1.04312ZM4.84553 7.10002L2.21234 2.586L13.2689 7.50002L2.21234 12.414L4.84552 7.90002H9C9.22092 7.90002 9.4 7.72094 9.4 7.50002C9.4 7.27911 9.22092 7.10002 9 7.10002H4.84553Z"
                                  fill="currentColor"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                              <span>
                                Send
                              </span>
                            </MDButton>
                          </div>
                        </form>
                      </Form>
                    </div>
                  </div>
                </div>
        </DialogPanel>
      </Dialog>
    </div>
  );
}